<template>
  <el-scrollbar class="scrollbar">
    <el-breadcrumb separator=">" class="breadcrumb">
      <el-breadcrumb-item v-for="breadcrumb in breadcrumbs" :key="breadcrumb.path" class="breadcrumb-item">
        <span v-if="!breadcrumb.redirect || breadcrumb.redirect === 'noredirect'">
          {{ breadcrumb.title }}
        </span>
        <!--
          We need to use this hacky solution with the <a> tag, as <router-link> is not working here
          => https://github.com/vuejs/router/issues/845
        -->
        <a
          v-else
          :data-test="`title-link-${breadcrumb.name}`"
          @click="handleBreadcrumbNavigation(breadcrumb)"
        >
          {{ breadcrumb.title }}
        </a>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </el-scrollbar>
</template>

<script>
import { currentLanguage } from '@/ui/lang/index'
import { generateTitle } from '@/ui/utils/i18n'
import * as R from 'ramda'

export default {
  name: 'Breadcrumb',
  data() {
    return {
      /** @type {Array<object>} */
      breadcrumbs: [],
      currentLanguage,
    }
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
  },
  watch: {
    $route(newRoute, previousRoute) {
      const hasChangedPage = previousRoute.name !== newRoute.name
      const hasChangedParams = !R.equals(previousRoute.params, newRoute.params)
      if (hasChangedPage || hasChangedParams) {
        this.setBreadcrumbs()
      }
    },
    currentLanguage() {
      this.setBreadcrumbs()
    },
  },
  created() {
    this.setBreadcrumbs()
  },
  methods: {
    async setBreadcrumbs() {
      const matchedRoutes = this.$route.matched.filter((item) => item.meta && item.meta.title)

      const promises = []
      for (const route of matchedRoutes) {
        const meta = route.meta ?? {}
        if (!meta.title) continue

        let titleFunc = meta.title
        if (typeof meta.title !== 'function') {
          titleFunc = async _ => generateTitle(meta.title)
        }

        const promise = async () => ({
          name: route.name,
          title: await titleFunc(this.$route.params),
          redirect: route.redirect,
        })
        promises.push(promise())
      }

      this.breadcrumbs = await Promise.all(promises)
    },
    handleBreadcrumbNavigation(item) {
      this.$router.push(this.getBreadcrumbRouteLocation(item))
    },
    getBreadcrumbRouteLocation(breadcrumbItem) {
      const { name, path } = breadcrumbItem.redirect

      const previouslyVisitedRoute = this.getPreviouslyVisitedRoute(name, path)
      return { name, params: this.$route.params, query: previouslyVisitedRoute?.query }
    },
    /**
     * @param {string} routeName - Route name of clicked breadcrumb, ex: "UseCases"
     * @param {string} routePath - Route path of clicked breadcrumb, ex: "/embed/:embedId/partners/:partnerId/use-cases". Empty by default.
     * @returns Route of a previously visited page by the user, or `undefined` if the route has not been visited yet.
     */
    getPreviouslyVisitedRoute(routeName, routePath = '') {
      /** "/embed/:embedId/partners/:partnerId/use-cases" matches -> `[':embedId', ':partnerId']` */
      const routeParamMatches = routePath.match(/:(\w+)/g) || []
      /** Remove colons to get the keys -> `['embedId', 'partnerId']` */
      const paramKeys = routeParamMatches.map(match => match.slice(1))

      const params = {}
      paramKeys.forEach(key => {
        params[key] = this.$route.params[key]
      })

      return this.visitedViews.find(viewRoute => {
        const hasSameParams = Object.keys(params).every(key => params[key] === viewRoute.params[key])
        return hasSameParams && viewRoute.name === routeName
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .scrollbar {
    width: calc(
      100%
      - 8px   // margin-left
      - 8px   // margin-right
      - 113px // right-menu
      - 50px  // hamburger
    );
  }

  .breadcrumb {
    display: flex;
    font-size: 18px;
    font-weight: 500;

    .breadcrumb-item {
      flex-shrink: 0;
      display: flex;
      height: 50px;
    }
  }
</style>
