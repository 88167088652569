<template>
  <el-collapse
    :model-value="collapseKey"
    class="nested-object-wrapper"
    accordion
  >
    <el-collapse-item :name="element.title" :title="element.title">
      <form-item
        v-for="(value, nestedElementKey) in model[element.key]"
        :key="nestedElementKey"
        :element-key="nestedElementKey"
        :rule-model="model[element.key]"
        :validation-props="validationProps[element.key]"
        :element="element.children[nestedElementKey]"
        :original-rule-model="originalRuleModel"
        @rule-model-change="onRuleChange"
        @field-focused="$emit('fieldFocused', $event)"
      />
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import * as R from 'ramda'
import FormItem from '@/ui-libs/schema-form-renderer/components/formItems/FormItem.vue'

/* eslint-disable vue/require-prop-types */
export default {
  name: 'CollapseFormItem',
  components: {
    FormItem,
  },
  props: [
    'element',
    'ruleModel',
    'elementKey',
    'validationProps',
    'originalRuleModel',
    'expandListIndex',
  ],
  emits: [
    'ruleModelChange',
    'fieldFocused',
  ],
  data() {
    return {
      areaModel: this.element.model[this.elementKey],
    }
  },
  computed: {
    model: {
      get() {
        return this.ruleModel
      },
      set(newData) {
        const data = newData.value || newData
        this.$emit('ruleModelChange', { value: data, elementKey: this.elementKey, expandListIndex: this.expandListIndex })
      },
    },
    collapseKey() {
      return this.elementKey && this.elementKey === 'area' && (this.areaModel?.start || this.areaModel?.end) ? this.element.title : ''
    },
  },
  methods: {
    onRuleChange({ value, elementKey }) {
      const updatedModel = R.clone(this.model)
      updatedModel[this.elementKey][elementKey] = value[elementKey]
      this.model = updatedModel
    },
  },
}
</script>

<style lang="scss" scoped>
.nested-object-wrapper {
  width: 100%;
  margin-bottom: 10px; // space below the form item
  border-bottom: none; // with the border-left in the `el-collapse-item__wrap` the current border is unnecessary and looks worse

  :deep(.el-collapse-item__wrap) {
    padding-left: 10px; // indent the contents of a collapsable form
    border-left: 2px solid var(--el-collapse-border-color); // provide a visible line to see what is nested
    border-bottom: none; // with the border-left the current border is unnecessary and looks worse

    .el-collapse-item__content {
      padding-bottom: 0; // override default 25px
    }
  }
}
</style>
