import { Auth } from '@aws-amplify/auth'
import { userApi } from '@/ui/api/user'
import { loadAllResourcePages } from '@/ui/utils/request'
import fetchUserByEmail from './fetchUserByEmail'
import { permissionApi } from '@/ui/api/permission'

export default {
  namespaced: true,
  state: () => ({
    userType: '',
    companyId: '',
    roles: [],
    customData: {},
    companyUsers: [],
  }),
  getters: {
    companyUsers: (state) => state.companyUsers,
    companyUser: (state) => (userId) => state.companyUsers.find((user) => user.id === userId) || {},
    userType: (state) => state.userType,
    companyId: (state) => state.companyId,
    fullName: (state) => state.customData.first_name + ' ' + state.customData.last_name,
    status: (state) => state.status,
    roles: (state) => state.roles,
    customData: (state) => state.customData,
    initials: (state) => state.customData.first_name[0] + state.customData.last_name[0],
  },
  actions: {
    setCustomData({ commit }, data) {
      commit('SET_CUSTOM_DATA', data)
    },

    async LogOut({ commit, dispatch }) {
      await dispatch('SetRoles', { roles: [] })
      try {
        await Auth.signOut()
        // Cognito-Amplify logout; AWS is working on it.
        // https://github.com/aws-amplify/amplify-js/issues/3435
      } catch (error) {
        console.log('Error signing out ', error)
      }

      window.location.reload() // force full refresh
    },

    async SetRoles({ commit, dispatch }, { roles, viewPermissions }) {
      commit('SET_ROLES', roles)
      await dispatch('permission/GenerateRoutes', { roles, viewPermissions }, { root: true })
    },

    async ConstructUserData({ dispatch, commit, state }) {
      let permissionResponse
      try {
        let hasFetchedCompany
        [permissionResponse, hasFetchedCompany] = await Promise.all([
          permissionApi.getViewsPermissions(),
          dispatch('company/fetchCompany', state.companyId, { root: true }),
        ])
        if (!hasFetchedCompany) {
          throw new Error('Failed to fetch company')
        }
      } catch (e) {
        await dispatch('LogOut')
      }

      const viewPermissions = permissionResponse?.data
      commit('permission/SET_PERMISSIONS', { viewPermissions }, { root: true })
      dispatch('SetRoles', { roles: [state.userType], viewPermissions })
    },

    async SetUser({ commit }, cognitoUser) {
      const user = await fetchUserByEmail(cognitoUser.username)
      commit('SET_CUSTOM_DATA', user)
      commit('SET_USER_TYPE', user.user_type)
      commit('SET_COMPANY_ID', user.company_id)
    },

    async fetchCompanyUsers({ dispatch, getters }, userIds) {
      if (!userIds) {
        await dispatch('fetchNewCompanyUsers')
        return
      }

      const uniqueUserIds = new Set(userIds)
      const companyUserIds = new Set(getters.companyUsers.map(user => user.id))
      const newUserIds = [...uniqueUserIds].filter(userId => userId && !companyUserIds.has(userId))

      if (newUserIds.length === 0) return
      await dispatch('fetchNewCompanyUsers', newUserIds)
    },
    async fetchNewCompanyUsers({ commit }, userIds) {
      const items = []
      const addItems = (newItems) => items.push(...newItems)
      const options = { filters: [] }
      if (userIds) {
        options.filters = [['id', 'in', userIds]]
      }
      await loadAllResourcePages(userApi.list, addItems, options)
      commit('SET_COMPANY_USERS', items)
    },
  },
  mutations: {
    SET_USER_TYPE: (state, userType) => {
      state.userType = userType
    },
    SET_COMPANY_ID: (state, companyId) => {
      state.companyId = companyId
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_CUSTOM_DATA: (state, userData) => {
      state.customData = userData
    },
    SET_COMPANY_USERS: (state, users) => {
      const filteredUsers = users.filter((user) => (
        !state.companyUsers.some((companyUser) => companyUser.id === user.id)
      ))

      state.companyUsers = [...state.companyUsers, ...filteredUsers]
    },
  },
}
