<template>
  <div class="lo-navbar">
    <hamburger :toggle-click="toggleSideBar" :is-active="sidebar.opened" class="hamburger-container" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <screenfull class="right-menu-item hover-effect" />
      <lang-select class="right-menu-item hover-effect" />
      <user-dropdown class="right-menu-item hover-effect" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { RouteName } from '@/ui/router/consts.js'
import Breadcrumb from './Breadcrumb'
import Hamburger from './Hamburger'
import LangSelect from './LangSelect'
import Screenfull from './Screenfull'
import UserDropdown from './UserDropdown.vue'

export default {
  name: 'Navbar',
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    LangSelect,
    UserDropdown,
  },
  data() {
    return {
      isVisible: false,
      RouteName,
    }
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app?.sidebar,
    }),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.lo-navbar {
  .dropdown {
    max-width: 200px;
    &__item {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    width: 113px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      line-height: 50px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }
  }
}
</style>
