<template>
  <div class="lo-tags-view">
    <scroll-pane ref="scrollPane" class="tags-view-wrapper">
      <router-link
        v-for="tag in shownVisitedViews"
        ref="tag"
        :key="tag.path"
        v-slot="{ navigate }"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        custom
      >
        <!--        <transition name="breadcrumb">-->
        <span
          role="link"
          :class="isActive(tag)?'active':''"
          class="tags-view-item"
          @click="navigate"
          @keypress.enter="navigate"
          @click.middle="closeSelectedTag(tag)"
          @contextmenu.prevent="openMenu(tag,$event)"
        >
          <template v-if="tag.appendTitle">
            {{ `${generateTitle(tag.title)}: ${tag.appendTitle}` }}
          </template>
          <template v-else>
            {{ generateTitle(tag.title) }}
          </template>
          <span v-if="!tag.meta.affix" class="icon-close" @click.prevent.stop="closeSelectedTag(tag)">
            <icon el-icon="ElIconClose" />
          </span>
        </span>
        <!--        </transition>-->
      </router-link>
    </scroll-pane>
    <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
      <li v-if="!(selectedTag.meta && selectedTag.meta.affix)" @click="closeSelectedTag(selectedTag)">
        {{ $t('tagsView.close') }}
      </li>
      <li @click="closeOthersTags">
        {{ $t('tagsView.closeOthers') }}
      </li>
      <li @click="closeAllTags(selectedTag)">
        {{ $t('tagsView.closeAll') }}
      </li>
    </ul>
  </div>
</template>

<script>
import ScrollPane from '@/ui/components/ScrollPane'
import { generateTitle } from '@/ui/utils/i18n'

export default {
  name: 'TagsView',
  components: {
    ScrollPane,
  },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
    }
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
    shownVisitedViews() {
      return this.visitedViews.length > 1 ? this.visitedViews : []
    },
    routers() {
      return this.$store.state.permission.routers
    },
  },
  watch: {
    $route() {
      this.addTags()
      this.scrollToTag()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
  },
  mounted() {
    this.initTags()
    this.addTags()
  },
  methods: {
    generateTitle, // generateTitle by vue-i18n
    isActive(route) {
      return route.path === this.$route.path
    },
    filterAffixTags(routes, basePath = '/') {
      let tags = []
      routes.forEach((route) => {
        if (route.meta && route.meta.affix) {
          tags.push({
            path: `${basePath}/${route.path}`,
            name: route.name,
            meta: { ...route.meta },
          })
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path)
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags]
          }
        }
      })

      return tags
    },
    initTags() {
      const affixTags = this.affixTags = this.filterAffixTags(this.routers)
      for (const tag of affixTags) {
        // Must have tag name
        if (tag.name) {
          this.$store.dispatch('tagsView/addVisitedView', tag)
        }
      }
    },
    addTags() {
      const { name } = this.$route
      if (!name) return

      const views = this.visitedViews
      const isNewView = !views.some(v => v.path === this.$route.path)
      if (isNewView) {
        this.$store.dispatch('tagsView/addView', this.$route)
        return
      }
      this.$store.dispatch('tagsView/updateVisitedView', this.$route)
    },
    scrollToTag() {
      this.$nextTick(() => {
        const tags = this.$refs.tag || []
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag)

            break
          }
        }
      })
    },
    async closeSelectedTag(view) {
      await this.$store.dispatch('tagsView/delView', view)
      if (this.isActive(view)) {
        await this.toLastView()
      }
    },
    async closeOthersTags() {
      await this.$store.dispatch('tagsView/delOthersViews', this.selectedTag)
      await this.toView(this.selectedTag)
    },
    async closeAllTags(view) {
      await this.$store.dispatch('tagsView/delAllViews')
      if (this.affixTags.some((tag) => tag.path === view.path)) {
        return
      }

      await this.toLastView()
    },
    async toLastView() {
      const lastView = this.visitedViews.slice(-1)[0] || '/'
      await this.toView(lastView)
    },
    async toView(view) {
      const failure = await this.$router.push(view)
      // Ignore navigation errors and set the tag of our current view, if it was deleted.
      if (failure) this.addTags()
    },
    openMenu(tag, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const { offsetWidth } = this.$el // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 15 // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }
      this.top = e.clientY

      this.visible = true
      this.selectedTag = tag
    },
    closeMenu() {
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.lo-tags-view {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid var(--border-color);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);

  .tags-view-wrapper {
    .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 26px;
      border: 1px solid #d8dce5;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 14px;
      margin-left: 5px;
      margin-top: 4px;
      &:first-of-type {
        margin-left: 15px;
      }
      &:last-of-type {
        margin-right: 15px;
      }
      &.active {
        background-color: #42b983;
        color: #fff;
        border-color: #42b983;
        &::before {
          content: '';
          background: #fff;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 5px;
        }
      }
    }
  }

  .icon-close {
    display: inline-block;
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 10px;
    border-radius: 50%;
    padding: 3px;
    margin-left: 3px;
    transition: background-color .3s ease;

    &:hover {
      background-color: #b4bccc;
      color: #fff;
    }
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 100;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>
