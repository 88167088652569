import basic from './basic'
import route from './route'
import table from './table'
import flow from './flow'
import connector from './connector'
import dataSource from './dataSource.js'
import flowdebugger from './flowdebugger'
import permission from './permission'
import profile from './profile'
import embedded from './embedded'
import transformation from './transformation'
import { envVariableList } from './envVariableList'
import { envVariableDialog } from './envVariableDialog'

export default {
  basic,
  route,
  table,
  flow,
  connector,
  dataSource,
  flowdebugger,
  permission,
  profile,
  embedded,
  transformation,
  entityAction: {
    add: 'Add',
    select: 'Select',
    select_tags: 'Select tags',
    select_status: 'Select status',
    search: 'Search...',
    open_filters: 'Open Filters',
    reset_filters: 'Reset Filters',
  },
  envVariableList,
  envVariableDialog,
  show_description: 'Show Description',
  company: {
    color: 'Company Colors',
    companyNotInStripe: 'Your company is not registered within our Stripe portal. Please contact support.',
    contactToActivate: 'Please contact us to activate your company for payments.',
    details_h1: 'Company Details',
    goToBilling: 'Go to billing portal',
    logo_upload: 'Upload Logo',
    primary_color: 'Primary Color',
    primary_color_desc: 'This is the primary color used in your company.',
    updatedSuccessfully: 'Company information has been updated successfully',
  },
  conauth: {
    connector: 'Connector',
    name: 'Name',
    status: 'Status',
    authtype: 'Auth Type',
    authtoken: 'Auth Token',
    authdetails: 'Auth Details',
    apiendpoint: 'Base domain - use only to overwrite standard enpoints',
    apiendpoint_tooltip: 'For SQL and FTP connector, this is used for host or DB-domain',
    credentials_error: 'The credentials could not be successfully verified, please check if you entered everything correctly and in accordance to the linked documentation',
    timed_out: 'The validation timed out. This usually happens if you specified a folder path in the URL or if your port is wrong or missing.',
    authorizationCanceled: 'We could not connect to {connectorName} because the authorization process was canceled. Please try again.',
    moreDetails: 'More Details',
    forceSave: 'Save regardless',
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    support: 'Support Center',
    whatsnew: 'What\'s new 🚀',
    systemstatus: 'System Status',
    theme: 'Theme',
    size: 'Global Size',
    addFields: 'Add own custom fields dynamically',
  },
  login: {
    title: 'Login Form',
    logIn: 'Log in',
    username: 'Username',
    password: 'Password',
    any: 'any',
    thirdparty: 'Or connect with',
    thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !',
  },
  documentation: {
    documentation: 'Documentation',
    github: 'Github Repository',
    visitForMoreInformation: 'Visit the documentation for further information',
  },
  components: {
    documentation: 'Documentation',
    dropzoneTips: 'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/ui/components/Dropzone.',
    stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1: 'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2: 'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name(default excel-list)',
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name(default file)',
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading pdf.',
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.',
  },
  tagsView: {
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All',
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo',
  },
  versioning: {
    title: 'History of ',
  },
  user: {
    changeEmail: 'To change the email address, please contact support.',
  },
  notifications: {
    errorPersists: 'If it persists on retry, please contact support!',
  },
}
