<template>
  <sidebar-logo
    class="logo"
    :collapse="!isSidebarOpened"
  />
  <el-scrollbar
    class="scrollbar"
  >
    <loadable-content :is-loading="!hasRoutes">
      <el-menu
        class="menu"
        popper-class="menu-popper"
        :default-active="defaultActive"
        :collapse="!isSidebarOpened"
        :collapse-transition="false"
      >
        <sidebar-menu
          :is-collapse="!isSidebarOpened"
          @event-click="openDialog = $event"
        />
      </el-menu>
    </loadable-content>
  </el-scrollbar>

  <flow-info-dialog
    v-if="openDialog === 'shortcut'"
    :on-close="() => openDialog = null"
  />
</template>

<script>
import { mapState } from 'vuex'
import LoadableContent from '@/ui/components/LoadableContent/index.vue'
import FlowInfoDialog from '@/ui/views/FlowBuilder/dialogs/FlowInfoDialog'
import SidebarLogo from './SidebarLogo'
import SidebarMenu from './SidebarMenu'

export default {
  name: 'Sidebar',
  components: {
    LoadableContent,
    SidebarLogo,
    SidebarMenu,
    FlowInfoDialog,
  },
  data() {
    return {
      openDialog: null,
    }
  },
  computed: {
    ...mapState({
      allowedRoutes: state => state.permission.routers,
      isSidebarOpened: state => state.app.sidebar.opened,
    }),
    defaultActive() {
      return String(this.$route.name)
    },
    hasRoutes() {
      return this.allowedRoutes?.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  height: var(--navbar-height);
}

.scrollbar {
  height: calc(100% - var(--navbar-height));
  background-color: #304156;
}

.menu {
  width: 100%;
  border: none; // remove element-plus default
  --el-menu-bg-color: #304156;
  --el-menu-text-color: #bfcbd9;
  --el-menu-active-text-color: #409EFF;
  --el-menu-hover-bg-color: #263445;

  :deep(.el-sub-menu) {
    --el-menu-bg-color: #1f2d3d;

    .submenu-item:hover {
      background-color: #001528;
    }
  }
}
</style>

<!--
  The popover gets rendered into an extra container outside the component,
  so it is necessary to have an unscoped style section here.
-->
<style lang="scss">
.menu-popper {
  padding: 0;
  --el-menu-bg-color: #304156;
  --el-menu-text-color: #bfcbd9;
  --el-menu-active-text-color: #409EFF;
  --el-menu-hover-bg-color: #263445;
}
</style>
