<template>
  <el-container class="layout">
    <el-aside :width="sidebarWidth">
      <sidebar />
    </el-aside>
    <el-container>
      <el-header class="header">
        <navbar class="navbar" />
        <tags-view class="tags-view" />
      </el-header>
      <el-main class="main">
        <loadable-content
          class="content"
          :is-loading="!hasRoutes"
        >
          <app-main />
        </loadable-content>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LoadableContent from '@/ui/components/LoadableContent/index.vue'
import AppMain from './AppMain.vue'
import TagsView from './TagsView.vue'
import Navbar from './Navbar/index.vue'
import Sidebar from './Sidebar/index.vue'
import ResizeMixin from './ResizeHandler'

export default {
  name: 'Layout',
  components: {
    LoadableContent,
    AppMain,
    Navbar,
    Sidebar,
    TagsView,
  },
  mixins: [
    ResizeMixin,
  ],
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      allowedRoutes: state => state.permission.routers,
      isSidebarOpened: state => state.app.sidebar.opened,
    }),
    hasRoutes() {
      return this.allowedRoutes?.length > 0
    },
    sidebarWidth() {
      return this.isSidebarOpened ? '210px' : '45px'
    },
  },
  created() {
    this.buildUserLayout()
  },
  methods: {
    ...mapActions({
      buildUserLayout: 'user/ConstructUserData',
    }),
  },
}
</script>

<style lang="scss" scoped>
.layout {
  height: 100vh;

  .header {
    padding: 0; // remove element-plus default
    height: calc(
      var(--navbar-height) +
      var(--tags-view-height)
    );
  }

  .navbar {
    height: var(--navbar-height);
  }

  .tags-view {
    height: var(--tags-view-height);
  }

  .main {
    padding: 0; // remove element-plus default
    overflow-x: hidden; // hide scrollbar which appears during transitions

    .content {
      height: 100%;
    }
  }
}
</style>
