import { Auth } from '@aws-amplify/auth'

const TOKEN_EXPIRATION_OFFSET = 10 * 60 // 10 minutes

const refreshSession = (user) => {
  const oldSession = user.getSignInUserSession()

  return new Promise((resolve, reject) => {
    user.refreshSession(oldSession.getRefreshToken(), async (error, session) => {
      if (error || !session || !session.accessToken) {
        reject(error)
      } else {
        resolve(session)
      }
    })
  })
}

const scheduleAccessTokenRenewal = async () => {
  const user = await Auth.currentAuthenticatedUser()
  let session = user.getSignInUserSession()
  let accessToken = session.getAccessToken()
  let expiration = accessToken.getExpiration() - accessToken.getIssuedAt()

  // Ensure no other tab already refreshed the session
  if (expiration < TOKEN_EXPIRATION_OFFSET) {
    try {
      session = await refreshSession(user)
    } catch (_) {
      // Refresh token is expired -> User needs to sign in again
      await Auth.signOut()
      window.location.reload()
      return
    }

    accessToken = session.getAccessToken()
    expiration = accessToken.getExpiration() - accessToken.getIssuedAt()
  }

  setTimeout(scheduleAccessTokenRenewal, (expiration - TOKEN_EXPIRATION_OFFSET) * 1000)
}

export { scheduleAccessTokenRenewal }
