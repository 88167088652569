<template>
  <div v-bind="$attrs" ref="codemirrorEditor" class="editor" />
</template>

<script>
import { GraphQLSchema } from 'graphql'
import { EditorState, Extension, EditorSelection, Compartment, Transaction, Text } from '@codemirror/state'
import { EditorView, keymap, lineNumbers, highlightActiveLineGutter, placeholder, highlightActiveLine } from '@codemirror/view'
import { defaultHighlightStyle, syntaxHighlighting, codeFolding, bracketMatching, foldGutter, foldKeymap, StreamLanguage } from '@codemirror/language'
import { defaultKeymap, history, historyKeymap, indentWithTab } from '@codemirror/commands'
import { searchKeymap, search, openSearchPanel, highlightSelectionMatches } from '@codemirror/search'
import { lintKeymap, linter, lintGutter } from '@codemirror/lint'
import { closeBrackets, closeBracketsKeymap } from '@codemirror/autocomplete'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { python } from '@codemirror/lang-python'
import { sql, PostgreSQL } from '@codemirror/lang-sql'
import { html } from '@codemirror/lang-html'
import { css } from '@codemirror/lang-css'
import { jinja2 } from '@codemirror/legacy-modes/mode/jinja2'
import { graphql } from 'cm6-graphql'
import { LanguageMode, EDITOR_COMPONENT_NAME } from '@/ui/components/Editor/const'
import { baseTheme, elementPlusInputTheme } from '@/ui/components/Editor/theme'
import { useAutocompleteSuggestion } from '@/ui/components/Editor/Autocomplete/functions.js'

const Emits = {
  /** Exists for when we only need to react to input change on the parent */
  UPDATE_MODEL_VALUE: 'update:modelValue',
  CARET_UPDATED: 'caretUpdated',
  FOCUS: 'focus',
  BLUR: 'blur',
  EXECUTE_KEYS: 'execute-keys',
  SCROLL: 'scroll',
}

export default {
  name: EDITOR_COMPONENT_NAME,
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: String,
      default: '',
    },
    /** `mode` was used to determine the language used by codemirror v5.
     * Our users have defined `mode` in various input fields (like a Connector Action).
     * We will continue using it and `LanguageMode` as the keyword for language.
     * @see {LanguageMode}
     */
    mode: {
      type: String,
      required: true,
    },
    /** A valid GraphQL schema must be supplied if mode == 'graphql' */
    graphqlSchema: {
      type: GraphQLSchema,
      default: undefined,
      validator(value, props) {
        return props.mode === 'graphql' && value
      },
    },
    lint: {
      type: Boolean,
      default: false,
    },
    lineWrapping: {
      type: Boolean,
      default: false,
    },
    /** Whether we should display the code editor similar to element-plus input */
    isInputUi: {
      type: Boolean,
      default: false,
    },
    /** This only disables editing it through events. DOM editing disabling is not implemented. */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    Emits.UPDATE_MODEL_VALUE,
    Emits.CARET_UPDATED,
    Emits.FOCUS,
    Emits.BLUR,
    Emits.EXECUTE_KEYS,
    Emits.SCROLL,
  ],
  data() {
    return {
      componentName: this.$options.name,
      /** @type {EditorView} */
      editor: null,
      editorReadOnly: {
        compartment: new Compartment(),
        extension: (disabled) => EditorState.readOnly.of(disabled),
      },
      editorLint: {
        compartment: new Compartment(),
        extension: () => null,
      },
      editorMultilineUi: {
        compartment: new Compartment(),
        extension: (showExtensions) => (showExtensions ? [
          lineNumbers(),
          foldGutter({ openText: '\u25BE', closedText: '\u25B8' }),
          highlightActiveLineGutter(),
        ] : []),
      },
    }
  },
  watch: {
    disabled(disabled) {
      this.editor.dispatch({
        effects: this.editorReadOnly.compartment.reconfigure(this.editorReadOnly.extension(disabled)),
      })
    },
    lint(value) {
      this.editor.dispatch({
        effects: this.editorLint.compartment.reconfigure(value ? this.editorLint.extension() : []),
      })
    },
  },
  mounted() {
    this.initializeEditor()
  },
  unmounted() {
    this.editor.scrollDOM.removeEventListener('scroll', this.onScroll)
  },
  /** @see {EditorExposed} */
  expose: [
    'componentName',
    'updateEditor',
    'forceFocusEditor',
    'triggerSearch',
    'insertAutocompleteSuggestion',
    'insertText',
    'selectAllText',
    'getState',
    'getCaretPosition',
  ],
  methods: {
    /**
     * A null `selection` means we don't care where the caret will be placed at, so we don't update it.
     * A null `input` means we want to reset it to its default value.
     * @type {EditorExposed.UpdateEditor}
     * @param {EditorUpdateModel} updateModel
     */
    updateEditor(updateModel) {
      const normalizedValue = updateModel.input ?? this.defaultValue
      /** @type {Transaction} */
      const transaction = {
        changes: { from: 0, to: this.editor.state.doc.length, insert: normalizedValue },
      }

      if (updateModel.selection) {
        transaction.selection = EditorSelection.cursor(updateModel.selection.to)
      }

      this.editor.dispatch(transaction)
    },
    /**
     * We need to use `setTimeout` to forcefully focus it in case the focus is removed by other events first.
     * For example clicking an autocomplete option with the mouse would focus us out, we want to focus after the click.
     * @type {EditorExposed.ForceFocusEditor}
     */
    forceFocusEditor() {
      setTimeout(() => this.editor.focus(), 0)
    },
    /** @type {EditorExposed.TriggerSearch} */
    triggerSearch() {
      openSearchPanel(this.editor)
    },
    /** @type {EditorExposed.SelectAllText} */
    selectAllText() {
      this.editor.dispatch({
        selection: EditorSelection.create([EditorSelection.range(0, this.editor.state.doc.length)]),
      })
    },
    insertAutocompleteSuggestion(value) {
      const fieldState = this.getState()

      const result = useAutocompleteSuggestion(fieldState.input, value, fieldState.selection)
      if (!result) {
        return
      }

      this.forceFocusEditor()
      this.insertText(result.input, result.selection)
    },
    /** @type {EditorExposed.InsertText} */
    insertText(text, selection) {
      const caretPos = this.editor.state.selection.main.head

      const from = selection?.from ?? caretPos
      const to = selection?.to ?? caretPos
      /** @type {Transaction} */
      const transaction = {
        changes: { from, to, insert: text },
        selection: EditorSelection.cursor(from + text.length),
      }

      /**
       We must have insertText in a timeout because otherwise clicking the scrollbar and selecting an option
       results in a `Calls to EditorView.update are not allowed while an update is in progress` error.
      */
      setTimeout(() => {
        this.editor.dispatch(transaction)
      }, 0)
    },
    /** @type {EditorExposed.GetState} */
    getState() {
      const caretPos = this.editor.state.selection.main
      const editorText = this.editor.state.doc.toString()
      return { selection: { from: caretPos.from, to: caretPos.to }, input: editorText }
    },
    async initializeEditor() {
      this.editor?.destroy()

      const initialDoc = Text.of((this.modelValue ?? this.defaultValue).split('\n'))

      this.editor = new EditorView({
        doc: initialDoc,
        extensions: [
          keymap.of([
            ...closeBracketsKeymap,
            ...defaultKeymap,
            ...searchKeymap,
            ...lintKeymap,
            ...historyKeymap,
            ...foldKeymap,
            this.isInputUi ? [] : indentWithTab,
          ]),
          syntaxHighlighting(defaultHighlightStyle),
          bracketMatching(),
          this.isInputUi ? [EditorView.lineWrapping, elementPlusInputTheme] : [
            baseTheme,
            this.getModeExtensions(this.mode),
            history(),
            codeFolding(),
            search({ top: true }),
            highlightSelectionMatches(),
            highlightActiveLine(),
          ],
          this.editorMultilineUi.compartment.of(this.editorMultilineUi.extension(this.shouldShowMultilineUiExtensions(initialDoc))),
          placeholder(this.placeholder),
          this.lineWrapping ? EditorView.lineWrapping : [],
          closeBrackets(),
          this.myUpdateListener(),
          this.editorReadOnly.compartment.of(this.editorReadOnly.extension(this.disabled)),
        ],
        parent: this.$refs.codemirrorEditor,
      })
      this.editor.scrollDOM.addEventListener('scroll', this.onScroll)
    },
    myUpdateListener() {
      return EditorView.updateListener.of(update => {
        if (update.focusChanged) {
          this.$emit(update.view.hasFocus ? Emits.FOCUS : Emits.BLUR)
        }

        const currentCaretPosition = update.state.selection.main.head
        const previousCaretPosition = update.startState.selection.main.head
        const inputValue = update.state.doc.toString()

        if (update.docChanged) {
          this.$emit(Emits.UPDATE_MODEL_VALUE, inputValue)
          this.editor.dispatch({
            effects: this.editorMultilineUi.compartment.reconfigure(
              this.editorMultilineUi.extension(this.shouldShowMultilineUiExtensions(update.state.doc)),
            ),
          })
        }

        const hasCaretPositionChanged = update.docChanged || previousCaretPosition !== currentCaretPosition
        if (hasCaretPositionChanged) {
          this.$emit(Emits.CARET_UPDATED)
        }
      })
    },
    onScroll() {
      this.$emit(Emits.SCROLL)
    },
    getCaretPosition() {
      const currentCaretPosition = this.editor.state.selection.main.head
      return this.editor.coordsAtPos(currentCaretPosition) || null
    },
    /**
     * If mode is not found defaults to providing extensions for `Jinja2`
     * @param {string} mode - a mode from `LanguageMode`
     * @returns {Array<Extension>} Resolves to an array of extensions required by the mode specified.
     */
    getModeExtensions(mode) {
      if (mode === LanguageMode.GraphQl) return [graphql(this.graphqlSchema), lintGutter()]
      if (mode === LanguageMode.Css) return [css()]
      if (mode === LanguageMode.Html) return [html()]
      if (mode === LanguageMode.Python) return [python()]
      if (mode === LanguageMode.Json) {
        this.editorLint.extension = () => linter(jsonParseLinter())
        const defaultLintExtension = this.lint ? this.editorLint.extension() : []
        return [json(), this.editorLint.compartment.of(defaultLintExtension), lintGutter()]
      }
      if (mode === LanguageMode.Sql || mode === LanguageMode.CustomSql) {
        const executeKeymap = keymap.of([
          {
            key: 'Meta-Enter', run: (editor) => {
              this.$emit(Emits.EXECUTE_KEYS)
              return true
            },
          },
        ])

        return [sql({ dialect: PostgreSQL }), executeKeymap]
      }

      return [StreamLanguage.define(jinja2)]
    },
    /**
     * @param {Text} doc
     * @returns {Boolean}
     */
    shouldShowMultilineUiExtensions(doc) { return !this.isInputUi || doc.lines > 1 },
  },
}
</script>

<style scoped lang="scss">
.editor {
  height: 100%;
  width: 100%;
}
</style>
