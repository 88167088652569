<template>
  <el-date-picker
    v-model="model"
    :type="type"
    :format="format"
    :range-separator="rangeSeparator"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    :default-value="null"
    :placeholder="placeholder"
    popper-class="custom-date-picker"
    :size="isEmbed ? 'large' : 'default'"
  />
</template>

<script>
/* eslint-disable vue/require-prop-types */

import { translateSchemaItem } from '@/ui-libs/schema-form-renderer/lang/index.js'

export default {
  name: 'DateField',
  props: [
    'entry',
    'ruleModel',
    'elementKey',
    'expandListIndex',
  ],
  emits: [
    'ruleModelChange',
  ],
  setup() {
    return {
      formatter: new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    }
  },
  data() {
    const data = {
      type: 'date',
      format: 'DD-MM-YYYY',
      rangeSeparator: null,
      startPlaceholder: null,
      endPlaceholder: null,
    }

    if (this.entry?.type) data.type = this.entry.type

    if (data.type === 'datetime') data.format = 'YYYY-MM-DD HH:mm:ss'
    if (data.type === 'daterange') {
      this.rangeSeparator = this.$t('form.toPreposition')
      this.startPlaceholder = this.$t('form.startDate')
      this.endPlaceholder = this.$t('form.endDate')
    }

    return data
  },
  computed: {
    placeholder() {
      return translateSchemaItem(this.$i18n.locale, this.entry.placeholder) || ''
    },
    model: {
      get() {
        const elementValue = this.ruleModel[this.elementKey]

        if (!elementValue) return null

        if (Array.isArray(elementValue)) {
          return elementValue.map((str) => new Date(str))
        }

        const isStringOfDates = typeof elementValue === 'string' && elementValue.includes(', ')
        if (isStringOfDates) {
          return elementValue.split(', ').map(str => new Date(str))
        }

        return new Date(elementValue)
      },
      set(newData) {
        if (this.type === 'daterange') {
          newData = newData?.map(date => this.toDateString(date)) ?? null
        } else if (this.type === 'date') {
          newData = this.toDateString(newData)
        } else {
          newData = newData?.toISOString() ?? null
        }

        this.$emit('ruleModelChange', {
          value: newData ?? null,
          expandListIndex: this.expandListIndex,
        })
      },
    },
    isEmbed() {
      return process.env.APPLICATION_TYPE === 'Embed'
    },
  },
  methods: {
    toDateString(date) {
      if (!date) return null
      const parts = this.formatter.formatToParts(date)
      return `${parts[4].value}-${parts[0].value}-${parts[2].value}`
    },
  },
}
</script>

<style lang="scss">
/** Cannot scope popper-class as it is not rendered inside the current element */
  .custom-date-picker {
    .el-time-panel{
      left: -30px;
    }
  }
</style>
