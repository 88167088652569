import { ref, Ref } from 'vue'
import Cookies from 'js-cookie'
import { createI18n } from 'vue-i18n'
import { LANGUAGES, LANGUAGE_KEY } from '@/ui/consts'
import enLocale from './en'
import deLocale from './de'

let language = Cookies.get(LANGUAGE_KEY) ?? window.navigator.language.substring(0, 2)
language = language in LANGUAGES ? language : LANGUAGES.en

/**
 * The language must be reactive so that plugin components like SchemaFormRenderer can watch changes to the language
 * @type {Ref<string>}
 * */
export const currentLanguage = ref(language)

export const i18n = createI18n({
  locale: currentLanguage.value,
  messages: {
    en: enLocale,
    de: deLocale,
  },
  missing(locale, key) {
    const chunks = key.split('.')
    let elm = chunks[chunks.length - 1]
    elm = elm.replace(/[-]|[_]/g, ' ')
    const values = elm.split(' ') || []
    const ret = []
    for (const m of values) {
      const nameCapitalized = m.charAt(0).toUpperCase() + m.slice(1)
      ret.push(nameCapitalized)
    }
    return ret.join(' ')
  },
})

export default i18n
