<template>
  <div style="position: relative;width: 100%;">
    <template v-if="hasAutocomplete">
      <on-click-outside @trigger="onFocus(false)">
        <div @focusin="handleEditorFocus">
          <editor
            ref="editor"
            v-model="model"
            :is-input-ui="true"
            :mode="LanguageMode.Json"
            :placeholder="placeholder"
            @focus="onFocus(true)"
            @caret-updated="onFocus(true)"
          />
        </div>
      </on-click-outside>
    </template>

    <el-input
      v-else
      v-model="model"
      :type="entry?.type"
      :placeholder="placeholder"
      :show-password="entry?.type === 'password'"
      :size="isEmbed ? 'large' : 'default'"
    />
  </div>
</template>

<script>
import { translateSchemaItem } from '@/ui-libs/schema-form-renderer/lang/index.js'
import { OnClickOutside } from '@vueuse/components'
import Editor from '@/ui/components/Editor/index.vue'
import { LanguageMode } from '@/ui/components/Editor/const.js'

export default {
  name: 'TextField',
  components: {
    Editor,
    OnClickOutside,
  },
  props: {
    entry: {
      type: Object,
      default: null,
    },
    ruleModel: {
      type: Object,
      required: true,
    },
    elementKey: {
      type: String,
      default: '',
    },
    element: {
      type: Object,
      default: null,
    },
    originalRuleModel: {
      type: Object,
      default: null,
    },
    expandListIndex: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'ruleModelChange',
    'fieldFocused',
  ],
  data() {
    return {
      isEmbed: process.env.APPLICATION_TYPE === 'Embed',
      fieldFocused: false,

      LanguageMode,
    }
  },
  computed: {
    placeholder() {
      return translateSchemaItem(this.$i18n.locale, this.entry.placeholder) || ''
    },
    model: {
      get() {
        return this.ruleModel[this.elementKey]
      },
      set(newData) {
        this.$emit('ruleModelChange', {
          value: newData,
          expandListIndex: this.expandListIndex,
        })
      },
    },
    hasAutocomplete() {
      return ![
        'email',
        'password',
      ].includes(this.entry.type)
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleTabKey)
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleTabKey)
  },
  methods: {
    /**
     * This handling exists mainly for the case where pressing "Tab" to move into the next field should select all the text.
     * However, this is also triggered when clicking on any padding present around the editor.
     *
     * Clicking in the editor input field itself, does not highlight all the text.
     * This is because CodeMirror editor sets the caret position when clicking into the field.
     * You can't simultaneously have all text selected and a caret position in the text.
     */
    handleEditorFocus() {
      /** @type {EditorExposed} */
      const editor = this.$refs.editor
      if (this.isTabFocus) {
        editor.selectAllText()
        this.isTabFocus = false
      }
    },
    handleTabKey(e) {
      if (e.key === 'Tab') {
        this.isTabFocus = true
      }
    },
    onFocus(isFocused) {
      this.fieldFocused = isFocused
      this.$emit('fieldFocused', this)
    },
  },
}
</script>
