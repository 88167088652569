export default {
  all: 'All',
  company: 'Company',
  valueNotSet: 'N/A',
  date_format: 'MM/DD/YYYY',
  date_time_format: 'DD/MM/YYYY HH:mm:ss',
  button: {
    ok: 'Ok',
    submit: 'Submit',
    accept: 'Accept',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    details: 'Details',
    editName: 'Edit name',
    stay_here: 'Stay here',
    create: 'Create',
    save: 'Save',
    delete: 'Delete',
    remove: 'Remove',
    update: 'Update',
    save_and_leave: 'Save and leave',
    discardChanges: 'Discard changes',
    discardAndLeave: 'Discard and leave',
    neverAskAgain: 'Never show this again',
    sync: 'Sync',
  },
  dialog: {
    confirmClose: 'Closing now will discard any unsaved changes. Are you sure?',
    confirmCloseTitle: 'Leave without saving',
    success: 'Success',
  },
  no: 'No',
  preposition: {
    to: 'to',
  },
  refresh: 'Refresh',
  settings: 'Settings',
  user: 'User',
  yes: 'Yes',
  deletedUser: 'Deleted User',
  loading: 'Loading...',
}
