export default {
  name: 'Flow Name',
  recent: 'Flow Runs',
  run_time: 'Last Run Time',
  start_time: 'Start Time',
  duration: 'Duration',
  monitoring: {
    quick_stats: 'All Flow Runs (past 7 days)',
    quick_action: 'Quick Actions',
    your_flows: 'Last updated Flows',
    running_flows: 'Currently running Flows',
    long_running_flows: 'Long running flows',
    flow_run_history: 'Last Flow Runs',
  },
  create_connector_auth: 'Create Connector Authentication',
  create_flow: 'Create Flow',
  create_user: 'Create User',
  search: 'Search flow... --> Type Salesforce, Zendesk or Magento',
  navigateFlowbuilder: 'Back to Flow Builder',
  navigateFlowbuilderTooltip: 'Go back to the Flow Builder',
  learnMore: 'Learn more...',
  noResultShown: {
    reasons: 'No result is shown due to the following possible reasons:',
    notRunReason: 'The step has not been run yet',
    retentionRateReason: 'The step\'s data was deleted as per retention settings',
    nilRetentionRateReason: 'Flow Data Retention Days is set to 0. We recommend to set it to at least 1 day while building and testing a flow.',
    controllerBusyReason: 'There is a high load of requests and the run execution has not yet been registered. Please try refreshing within a moment.',
  },
  looper: {
    of: ' of',
  },
  props: {
    looper_completed_iterations: 'Iterations:',
    looper_total_iterations: 'Total iterations:',
    looper_iteration_index: 'Iteration index:',
    automatic_pagination_pages: 'Retrieved pages:',
    created_date: 'Created:',
    updated_date: 'End:',
    start_date: 'Start:',
    end_date: 'End:',
    retrying_in: 'Retrying in {amount}',
    request_url: 'Request URL:',
    response_status: 'Response code:',
    flow_trigger: 'Flow Trigger',
    triggered_by_flow: 'Triggered by Flow',
    triggered_by_user: 'Triggered by User',
    cancel_types: {
      user: 'Canceled by user',
      step_size: 'Canceled due to step limit',
    },
    original_flow_run: 'Original Flow Run at',
    trigger_types: {
      scheduler: 'Scheduler',
      webhook_async: 'Webhook (Async)',
      webhook_sync: 'Webhook (Sync)',
      multi_flow_trigger_async: 'Multi Flow Trigger (Async)',
      multi_flow_trigger_sync: 'Multi Flow Trigger (Sync)',
      re_run: 'Re-Run',
      super_action: 'Super Action',
      manual: 'Manual',
      pre_save_flow: 'Pre save',
      on_delete_flow: 'On delete',
      file_uploader: 'File Uploader',
      error_handling: 'Error handling',
      single_step: 'Single step execution',
    },
  },
  schemaExtenderTitle: 'Add own custom API fields to actions',
  customFieldTitle: 'Custom field name (the field-name or key used by the API)',
  customFieldType: 'Field Type',
  toolbar: {
    newFlowActionText: 'New flow',
    newFlowActionTooltip: 'Create a new Flow',

    saveFlowActionText: 'Save',
    saveFlowActionTooltip: 'Save flow to your collections',

    loadFlowActionText: 'Load',
    loadFlowActionTooltip: 'Open an existing flow from your saved collections',

    settingsActionTooltip: 'Edit the settings of this flow',

    copyFlowActionText: 'Copy',
    copyFlowActionTooltip: 'Copy',

    runFlowActionText: 'Run Now',
    runFlowActionTooltip: 'Run a flow now to test it!',

    debugFlowActionText: 'Debug Flow',
    debugFlowActionTooltip: 'Checkout the debugger output of the flow',

    deleteFlowActionText: 'Delete',
    deleteFlowActionTooltip: 'Delete the flow and all its debugging flow runs',

    showFlowInfoText: 'Tips',
    showFlowInfoTooltip: 'Shortcuts and tips on flow building 🚀',

    shareFlowActionText: 'Share',
    shareFlowActionTooltip: 'Sharing',

    versioningFlowText: 'Version History',
    versioningFlowTooltip: 'Version history',

    toggleReferencePanelText: 'Reference Panel',
    toggleReferencePanelTooltip: 'Toggle Function Reference Panel for steps',

    insufficientPermissions: 'Insufficient permissions',
    insufficientPermissionsToDeleteTooltip: 'Insufficient permissions to delete the flow',
    insufficientPermissionsToVersioningTooltip: 'Insufficient permissions to view the versioning history',
    insufficientPermissionsToSaveTooltip: 'Insufficient permissions to save the flow',
    insufficientPermissionsToShareTooltip: 'Insufficient permissions to edit the sharing settings',
    insufficientPermissionsToRunTooltip: 'Insufficient permissions to run this flow',
    insufficientPermissionsToCancelFlowRun: 'Insufficient permissions to cancel the flow',
    insufficientPermissionsToRetryFlowRun: 'Insufficient permissions to retry the flow',
    insufficientPermissionsToRunFlowRunSuperAction: 'Can\'t run a super action flow run. Please, run the flow that uses the super action instead',
    insufficientPermissionsToRetryFlowRunSuperAction: 'Can\'t retry a super action flow run. Please, retry the flow that uses the super action instead',
  },
  unsaved: {
    connectorAdded: 'Connector was added',
    connectorMoved: 'Connector was moved',
    connectorChanged: 'Connector was changed',
    connectorDeleted: 'Deleted connector',
    linkUpdated: 'Link was updated',
    linkDeleted: 'Deleted link',
    formUpdated: 'Unsaved changes in form',
    attention: 'Attention',
    lostChanges: 'The following changes would be lost:',
    saveBeforeRun: 'You can\'t run a flow until the following changes have been saved:',
    linkAdded: 'Link was added',
    errorLinkAdded: 'Error link was added',
    linkConnectsTwoNodes: 'Link connects {sourceNodeSelector} and {targetNodeSelector}',
    linkConnectedToANode: 'Link connected to {node}',
  },
  zoom_hint: 'To adjust the zoom and focus of the Workspace click or press CMD or CTRL + mouse scroll and change the position of the mouse pointer',
  advancedSchemaView: {
    expectsType: 'Expects {type}',
    expectsValues: 'Expects one of the following values: {values}',
    fieldKey: 'Key: {key}',
    title: 'Advanced view',
  },
  savingErrors: {
    selfLinkError: '{name} - {selector}: arrows can\'t be connected to the step they\'re coming from!',
    excessiveLinkWarning: '{name} - {selector}: You have used more than one outgoing arrow!',
    excessiveErrorLinkWarning: '{name} - {selector}: You have used more than one "Error" arrow!',
    excessiveActionLoopLinkWarning: '{name} - {selector}: You have used more than one "ActionLoop" arrow!',
    booleanLinksWarning: '{name} - {selector}: Boolean helper can only have one "True" and/or one "False" arrows!',
    actionLoopArrowIsRequired: '{name} - {selector}: "ActionLoop" arrow is required for the Looper Helper. You can add it by adding a "Link" and double clicking it to write "ActionLoop".',
    actionLoopHasNoTarget: '{name} - {selector}: "ActionLoop" arrow must target a step!',
    emptyAction: '{name} - {selector}: Does not have an {action}. Please set it by double clicking the step and applying the changes.',
    multiCaseHelperLabels: '{name} - {selector}: Cannot have arrows without labels!',
    referenceIsASystemWideConstant: 'The reference for "{title}" can not be "{selector}" because it is a system wide constant',
    referenceFormatIsNotValid: 'Step Reference format is not valid',
    invalidConnectorAuth: 'Connector Auth in Connector step {selector} doesn\'t include any of the tags assigned to this Flow: {flowTags}.',
  },
  activeSaveFlow: {
    buttonChangeToPaused: 'Change status to Paused and save.',
    message: 'Your flow is Active, it is best practice to set a flow to paused for testing, in order to avoid any unwanted retries',
  },
  restoreFromLocal: {
    description: 'Do you want to restore your changes from the last auto save at {localDate} ({localDistance} ago) or' +
      ' want to keep the last manually saved version from {remoteDate} ({remoteDistance} ago)?',
    discard: 'Keep last manually saved version',
    restore: 'Restore auto saved version',
    restoreToNewFlow: 'Open restored version as new flow',
  },
  editModeTitle: {
    stepTitle: 'Step Title',
    refId: 'Step Reference',
    dynamicTitlePostfix: 'Dynamic Title Postfix',
    dynamicAuthId: 'Dynamic Authentication ID',
    errorHandler: 'Error Handling',
    errorHandlingFlowEnabled: 'Triggers Error Handling Flow',
    accessibleInErrorHandlingFlow: 'Accessible in Error Handling Flow',
    description: 'Description',
  },
  errorHandlingOptions: {
    terminate: 'Terminate',
    continue: 'Continue',
    skip_loop: 'Skip loop iteration',
    break_inner_loop: 'Break inner loop',
    manual: 'Manual handling',
  },
  errorHandlingFlow: 'Error Handling Flow',
  errorHandlingFlowTooltip: 'A custom Flow to handle all errors or log errors and send error logs to a destination of your choosing. More information can be found in our docs.',
  errorHandlingStepConfirmDeleteMessage: 'The output of the step is used in the Error Handling Flow. Are you sure you want to delete this step?',
  errorHandlingStepConfirmDeleteTitle: 'Delete the step: {stepTitle}?',
  retrieveAllData: 'Retrieve all data',
  oneNodePerFlowError: 'Only one <strong style="color: #4372C4">{nodeName}</strong> per flow is allowed.',
  actionLoopRequires: 'The <strong style="color: #4372C4">ActionLoop</strong> arrow is <strong>required</strong> for the <strong style="color: #4372C4">Looper Helper</strong>.',
  enableFunctionReferencePanel: 'Function Reference Panel was enabled',
  disableFunctionReferencePanel: 'Function Reference Panel was disabled',
  updateBulkFlowsError: 'You can only bulk update 50 Flows at once',
  enableFilteringByTags: 'Enable filtering by Tags',
  enableFilteringByTagsExplanation: 'In Flow Builder, only Connector Auths having at least one tag in common with the Flow will be accessible.',
  functionsReference: 'Functions Reference',
  metadata: 'Metadata',
  enableRemoteSearchAuthorization: 'Enable authorization for remote search',
}
