<template>
  <collapse-form-item
    v-if="elementName === 'nested-object-field'"
    v-bind="$props"
    @rule-model-change="onRuleChange"
    @field-focused="$emit('fieldFocused', $event)"
  />
  <expand-list-form-item
    v-else-if="elementName === 'expanding-list-field'"
    v-bind="$props"
    @rule-model-change="onRuleChange"
    @field-focused="$emit('fieldFocused', $event)"
  />
  <form-item
    v-else
    v-bind="$props"
    @remove-custom-field="removeCustomField"
    @update-custom-field="$emit('update-custom-field', $event)"
    @rule-model-change="onRuleChange"
    @field-focused="$emit('fieldFocused', $event)"
  />
</template>

<script>
import CollapseFormItem from '@/ui-libs/schema-form-renderer/components/formItems/CollapseFormItem.vue'
import ExpandListFormItem from '@/ui-libs/schema-form-renderer/components/formItems/ExpandListFormItem.vue'
import FormItem from '@/ui-libs/schema-form-renderer/components/formItems/FormItem.vue'

/**
 * The NestedFormItem is the main entry point for any single form item. It could be a Text or an ExpandList or anything else.
 * It is mainly just a logical controller on what to display.
 * What it displays is determined by the `elementName`.
 * If there are no more nestings then the FormItem is what will determine the type of field that it will be.
 */

/* eslint-disable vue/require-prop-types */
export default {
  name: 'NestedFormItem',
  components: {
    CollapseFormItem,
    ExpandListFormItem,
    FormItem,
  },
  props: [
    'element',
    'ruleModel',
    'elementKey',
    'validationProps',
    'originalRuleModel',
    'expandListIndex',
    'graphqlSchema',
  ],
  emits: [
    'ruleModelChange',
    'update-custom-field',
    'remove-custom-field',
    'fieldFocused',
  ],
  computed: {
    elementName() {
      return this?.element?.elementName
    },
  },
  methods: {
    removeCustomField(elementTitle) {
      this.$emit('remove-custom-field', elementTitle)
    },
    onRuleChange(newData) {
      const data = newData.value
      this.$emit('ruleModelChange', { value: data, elementKey: this.elementKey, expandListIndex: this.expandListIndex })
    },
  },
}
</script>
