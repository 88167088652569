import { Amplify } from '@aws-amplify/core'

export const configureAmplify = () => {
  const config = {
    Analytics: { disabled: true },
    aws_project_region: process.env.AWS_REGION,
    aws_content_delivery_bucket: process.env.AMPLIFY.CONTENT_DELIVERY_BUCKET,
    aws_content_delivery_bucket_region: process.env.AWS_REGION,
    aws_content_delivery_url: process.env.AMPLIFY.CONTENT_DELIVERY_URL,
    aws_cognito_region: process.env.AWS_REGION,
    aws_user_pools_id: process.env.COGNITO.USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.COGNITO.USER_POOL_WEB_CLIENT_ID,
    oauth: {},
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 12,
      passwordPolicyCharacters: [
        'REQUIRES_LOWERCASE',
        'REQUIRES_NUMBERS',
        'REQUIRES_SYMBOLS',
        'REQUIRES_UPPERCASE',
      ],
    },
  }
  if (process.env.COGNITO.ENDPOINT) {
    config.endpoint = process.env.COGNITO.ENDPOINT
    // By default, amplify uses USER_SRP_AUTH which isn't supported by cognito-local,
    // downgrade to USER_PASSWORD_AUTH flow type.
    config.Auth = { authenticationFlowType: 'USER_PASSWORD_AUTH' }
  }

  Amplify.configure(config)
}
