<template>
  <el-config-provider :locale="locale">
    <layout />

    <theme-picker
      v-show="false"
      :theme="companyColor"
      should-trigger-change
    />
  </el-config-provider>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { LANGUAGES } from '@/ui/consts'
import { userApi } from '@/ui/api/user.js'
import Layout from '@/ui/layout'
import ThemePicker from '@/ui/views/CompanyPreference/ThemePicker'

/* https://github.com/element-plus/element-plus/commit/3ab31b4d607d97355d5acf5887f37d3da0b8ca99
* TODO: This was a breaking change. Go back to using this once the new element-plus version includes an update to this.
import en from 'element-plus/lib/locale/lang/en'
import de from 'element-plus/lib/locale/lang/de'
*/
import en from '../../node_modules/element-plus/lib/locale/lang/en'
import de from '../../node_modules/element-plus/lib/locale/lang/de'

export default {
  name: 'App',
  components: {
    Layout,
    ThemePicker,
  },
  computed: {
    ...mapState({
      language: (state) => state.app.language,
    }),
    ...mapGetters({
      company: 'company/getCompany',
      customUserData: 'user/customData',
    }),
    locale() {
      return this.language === LANGUAGES.de ? de : en
    },
    companyColor() {
      return this.company?.styles?.primaryColor
    },
  },
  async mounted() {
    const isLanguageSet = !!this.customUserData?.preferred_language
    const isLanguageSupported = this.customUserData.preferred_language in LANGUAGES
    const needsToSetLanguage = !isLanguageSet || !isLanguageSupported
    if (needsToSetLanguage) {
      await this.setUserPreferredLanguage()
    }

    await this.setAppLanguage(this.customUserData.preferred_language)
  },
  methods: {
    ...mapActions({
      setUserCustomData: 'user/setCustomData',
      setAppLanguage: 'app/setLanguage',
    }),
    async setUserPreferredLanguage() {
      const browserLanguage = window.navigator.language.substring(0, 2) === LANGUAGES.de ? LANGUAGES.de : LANGUAGES.en

      let userData
      try {
        userData = (await userApi.update({ ...this.customUserData, preferred_language: browserLanguage })).data
      } catch (error) {
        return
      }

      await this.setUserCustomData(userData)
    },
  },
}
</script>
