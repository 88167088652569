import * as R from 'ramda'
import { ExtendedError, ExtendedErrorSeverity } from '@/ui/classes/error'
import get from 'lodash.get'
import router from '@/ui/router'
import { GraphQLNamedType } from 'graphql'

/**
 * Split the jinja expression into a path.
 * Transform the path naively into a sequence of property-access operations.
 * The following is not designed to hold for any complex input, just for the most basic stuff.
 * This regex tests for property access, array-access, function calls, and unterminated versions of the above.
 *
 * Example:
 *
 * "asd1.q.1[2asd'asd'].r('asd').asd[a)".match(jinjaPathItemRegex)
 *
 * [ 'asd1', '.q', '.1', "[2asd'asd']", '.r', "('asd')", '.asd', '[a)' ]
*/
const jinjaPathItemRegex = /([a-zA-Z_]\w*)|(\.\w+)|(\[[^]]+\])|(\([^)]*\))|(\..*)|(\[.*)|(\(.*)/g
const validCharRegex = /^[a-zA-Z0-9_./]+$/
export const LooperContextOption = {
  INSIDE: 'Inside Loop',
  OUTSIDE: 'Outside Loop',
}
/**
 * Iterates over the line of text to the left to find the first valid character.
 * Gets the closest separator before `caretIndex` in a `text` and returns the index of the next character if it is valid.
 *
 * A separator is anything that is not considered a valid character by the `validCharRegex`.
 * It is used to indicate the end/start of a sequence of characters.
 *
 * Example:
 *
 * "hi name :test" -> If the selection is "test" returns the index of the caret position before the first character "t" (9).
 *
 * If the selection is "name" returns the index of the caret position before the character "n" (3).
 * @param {string} text - The written input from the current line in the text field
 * @param {number} caretIndex - The position of the caret in the text field
 * @returns {number} Gets the index of the first valid character
*/
function getPathStartIndex(text, caretIndex) {
  const indexOfCharBeforeCaret = caretIndex - 1

  let currentCharIndex = indexOfCharBeforeCaret
  while (currentCharIndex >= 0) {
    const isValidChar = validCharRegex.test(text[currentCharIndex])
    if (!isValidChar) {
      break
    }
    currentCharIndex--
  }

  const hasValidChar = currentCharIndex < indexOfCharBeforeCaret
  const firstValidCharIndex = currentCharIndex + 1
  return hasValidChar ? firstValidCharIndex : undefined
}

/**
 * Iterates over the line of text to the right to find the last valid character.
 *
 * Example:
 *
 * "hi name :test" -> If the selection is "name" returns the index for the caret position after character "e" (7).
 *
 * @param {string} text - The value of the text field
 * @param {number} caretIndex - The position of the caret in the text field
 * @returns {number} Returns the position of the last valid character
 */
function getPathEndIndex(text, caretIndex) {
  let currentCharIndex = caretIndex
  while (currentCharIndex < text.length) {
    const isValidChar = validCharRegex.test(text[currentCharIndex])
    if (!isValidChar) {
      break
    }
    currentCharIndex++
  }
  const hasValidChar = currentCharIndex >= caretIndex
  return hasValidChar ? currentCharIndex : undefined
}

/**
 * @param {string} inputValue
 * @param {string} selectedCompletion
 * @param {EditorSelection} selection
 * @returns {EditorUpdateModel}
 */
export function useAutocompleteSuggestion(inputValue, selectedCompletion, selection) {
  const pathStartIndex = getPathStartIndex(inputValue, selection.from)
  if (pathStartIndex === undefined) return null

  const path = inputValue.slice(pathStartIndex, selection.from)
  const pathItems = path.match(jinjaPathItemRegex)
  if (!pathItems) return null

  // The following will break in special cases.
  const onlyOneItem = pathItems.length === 1
  const hasSpecialSymbols = String(selectedCompletion).match(/[^\w]/)
  const pathItemReplacement = onlyOneItem ? selectedCompletion : hasSpecialSymbols ? `["${selectedCompletion}"]` : `.${selectedCompletion}`

  // Replace the last one.
  pathItems.splice(-1, 1, pathItemReplacement)

  const newPath = pathItems.join('')

  return {
    input: newPath,
    selection: {
      from: pathStartIndex,
      to: pathStartIndex + path.length,
    },
  }
}

const isFunctionCall = R.startsWith('(')

function isPropertyAccess(item) {
  const regex = /\[["']?(\w+[\w\s]*)["']?\]|\.(\w+)/
  const match = item.match(regex)
  return match && (match[1] || match[2])
}

/**
 * @param {String} item
 * @returns String - Returns the name of the last unclosed property.
 * E.g., if the path were `set_variables.stuff`, this would return `stuff`.
 * E.g., if the path were `set_variables.`, this would return an empty string.
 */
function lastUnclosedPropertyAccess(item) {
  const regex = /\[(\w*)|\.(\w*)/
  const match = item.match(regex)
  return match && (match[1] || match[2])
}

function stepThroughExample(pathValue, pathItem) {
  if (!R.isNil(pathValue)) {
    // Ignore function calls.
    if (isFunctionCall(pathItem)) {
      return pathValue
    }

    const propertyMatch = isPropertyAccess(pathItem)
    if (propertyMatch) {
      return pathValue[propertyMatch]
    }
  }
  return undefined
}

function stepThroughSchema(pathSchema, pathItem) {
  if (!R.isNil(pathSchema)) {
    // Ignore function calls.
    if (isFunctionCall(pathItem)) {
      return pathSchema
    }

    const propertyMatch = isPropertyAccess(pathItem)
    if (propertyMatch) {
      if (pathSchema.type === 'array') {
        // TODO Check that the item is really a number.
        return pathSchema.items
      }

      if (pathSchema.type === 'object') {
        return pathSchema.properties[propertyMatch]
      }
    }
  }
  return undefined
}

const buildSuggestion = (function_name, desc, connectorId) => ({ function_name, desc, connectorId })

function suggestFromExample(example, pathItems, lastPathItem, parentConnectorId) {
  // Step through the example values with exact matches.
  const lastFullPathValue = pathItems.reduce(stepThroughExample, example)

  // If the last one is some kind of property access, then we try further.
  const property = lastUnclosedPropertyAccess(lastPathItem)

  if (R.is(Array, lastFullPathValue)) {
    const itemToSuggestion = (value, index) => buildSuggestion(String(index), JSON.stringify(value), parentConnectorId)
    const localSuggestions = lastFullPathValue.map(itemToSuggestion)
    if (!localSuggestions) return []

    return localSuggestions.filter(suggestion => {
      const lowerCasedSuggestion = suggestion.function_name.toLowerCase()
      const lowerCasedProperty = property?.toLowerCase()
      return lowerCasedSuggestion.match(lowerCasedProperty) && lowerCasedSuggestion !== lowerCasedProperty
    })
  }

  if (R.is(Object, lastFullPathValue)) {
    const entryToSuggestion = ([key, value]) => buildSuggestion(key, JSON.stringify(value), parentConnectorId)
    const localSuggestions = Object.entries(lastFullPathValue).map(entryToSuggestion)
    if (!localSuggestions) return []

    return localSuggestions.filter(suggestion => {
      const lowerCasedSuggestion = suggestion.function_name.toLowerCase()
      const lowerCasedProperty = property?.toLowerCase()
      return lowerCasedSuggestion.match(lowerCasedProperty) && lowerCasedSuggestion !== lowerCasedProperty
    })
  }

  return []
}

function suggestFromSchema(schema, pathItems, lastPathItem) {
  // Step through the schema with exact matches.
  const lastFullPathSchema = pathItems.reduce(stepThroughSchema, schema)

  if (!R.isNil(lastFullPathSchema)) {
    // If the last one is some kind of property access, then we try further.
    const property = lastUnclosedPropertyAccess(lastPathItem)

    if (lastFullPathSchema.type === 'array') {
      return [buildSuggestion('<index>')]
    }

    if (lastFullPathSchema.type === 'object') {
      const entryToSuggestion = ([key, value]) => buildSuggestion(key, R.is(Array, value.type) ? value.type.join(', ') : value.type)
      const localSuggestions = Object.entries(lastFullPathSchema.properties).map(entryToSuggestion)
      if (!localSuggestions) return []

      return localSuggestions.filter(suggestion => {
        const lowerCasedSuggestion = suggestion.function_name.toLowerCase()
        const lowerCasedProperty = property?.toLowerCase()
        return lowerCasedSuggestion.match(lowerCasedProperty)
      })
    }
  }

  return []
}

/**
 * @param {Connector} connector
 * @param {string} wordToAutocomplete
 * @param {Array<AutocompleteOption>} autocompleteSuggestions
 * @param {SchemaFormRendererConfig} flowContext - The context of the current flow and diagram
 * @param {string} looperContext - LooperContextOption
 * @param {string} inputRef
 * @returns {Array<AutocompleteOption>} The suggestions that match the input or null, if no suggestions matched
 */
export function matchAutocompleteSuggestions(
  connector,
  wordToAutocomplete,
  autocompleteSuggestions,
  { currentFlow, diagramNodes },
  looperContext,
  inputRef,
) {
  const pathItems = wordToAutocomplete.match(jinjaPathItemRegex)
  if (!pathItems) {
    return []
  }

  if (isPropertyNameInDictHelperFilterListV2Modified(diagramNodes, inputRef)) {
    const dictHelperListReferenceValue = getListReferenceValue()
    return generateSuggestionsForPropertyName(diagramNodes, autocompleteSuggestions, pathItems, dictHelperListReferenceValue)
  }

  const rootItem = pathItems[0]

  // In this case we simply try to find some matching value and list all matches.
  // If we have more than one path item then the input value is a path (or a function call).
  if (pathItems.length === 1) {
    const suggestions = autocompleteSuggestions.filter(suggestion => {
      const lowerCasedSuggestion = suggestion.function_name.toLowerCase()
      const lowerCasedRoot = rootItem.toLowerCase()
      return lowerCasedSuggestion.match(lowerCasedRoot) && lowerCasedSuggestion !== lowerCasedRoot
    })
    if (suggestions.length === 0) return []
    return suggestions
  }

  const looperSuggestions = getIterateOverLooperSuggestions(autocompleteSuggestions, pathItems, connector, currentFlow, diagramNodes, looperContext)
  const suggestions = looperSuggestions ?? getFlowStepSuggestions(autocompleteSuggestions, pathItems)
  if (suggestions.length === 0) return []
  return suggestions
}

/**
 * @param {string} inputValue
 * @param {EditorSelection} selection
 * @returns {string} The word that we want to search by
 */
export function getSearchWord(inputValue, selection) {
  const pathStart = getPathStartIndex(inputValue, selection.from)
  const pathEnd = getPathEndIndex(inputValue, selection.to)

  if (pathStart === undefined) {
    return ''
  }

  const charsBeforeCaret = inputValue.slice(pathStart, selection.from)
  const charsAfterCaret = inputValue.slice(selection.to, pathEnd)
  return charsBeforeCaret + charsAfterCaret
}

/**
 * TODO:keyword: REFACTOR_helper_actions_in_code - checking this should be a lot easier with the Filter List V2 defined in code.
 * Returns true if the user modifies Property Name's value in a Dict Helper node with 'Filter list v2' action;
 * otherwise, returns false.
 * Right now, the addInfo.activeActionId value for every node is only set after the user has applied the changes.
 * Otherwise, its value is null. Therefore, we can not rely on taking the value from addInfo.activeActionId.
 * @param {Object} diagramNodes
 * @param {String} inputRef
 * @returns {Boolean}
 */
function isPropertyNameInDictHelperFilterListV2Modified(diagramNodes, inputRef) {
  const hasAccessToDiagram = !!diagramNodes
  if (!hasAccessToDiagram || inputRef !== 'property') return false

  // We get the selected step id from URL
  const selectedItemId = router.currentRoute.value.query.selectedFlowStepId
  const divActionSelect = document.querySelector('.action-cascader')
  const actionValue = divActionSelect.querySelector('input').value
  const isFilterListV2Action = actionValue === 'Filter List V2'
  if (!isFilterListV2Action) return false

  return diagramNodes?.some(node => node.id === selectedItemId)
}

/**
 * TODO:keyword: REFACTOR_helper_actions_in_code - once we have the helper in code we can remove this fragile code
 * If the selectedItem is a Dict Helper, the action is 'Filter List V2', and the user is modifying the value
 * for the Property Name field, we fetch the value for the List reference field.
 * Right now, the user_input values are only set after the user has applied the changes; otherwise, they are
 * all set to null. Therefore, we can not rely on user_input values.
 * @returns {String}
 */
function getListReferenceValue() {
  const form = document.querySelector('.comp-schema-form-renderer')
  const listReferenceField = form.querySelector('#nestedItem-list_reference')
  // We select the first line of the editor for the List reference field.
  return listReferenceField.querySelector('.cm-line').innerHTML
}

/** todo: refactor this method to not duplicate parts of code
 *
 * Generate suggestions for the Property Name field.
 * Those suggestions should be based on the reference in the List Reference field (dictHelperListReferenceValue).
 * @param {Object} diagramNodes
 * @param {String} connectorId
 * @param {Object} autocompleteSuggestions
 * @param {String} pathRoot
 * @returns {Object} - Contains the suggestions that are going to be shown in the list
 */
export function generateSuggestionsForPropertyName(diagramNodes, autocompleteSuggestions, path, dictHelperListReferenceValue) {
  const hasListReferenceValue = !R.isNil(dictHelperListReferenceValue) && !R.isEmpty(dictHelperListReferenceValue)
  if (!hasListReferenceValue) return []

  const targetNode = diagramNodes.find(node => node.addInfo?.selector === dictHelperListReferenceValue)
  if (!targetNode) return []

  const targetNodeSuggestions = autocompleteSuggestions.find(({ flowRunStep }) => flowRunStep?.node_id === targetNode.id)
  let nodeSuggestions = targetNodeSuggestions?.flowRunStep?.response_json?.[0]
  if (!nodeSuggestions) return [] // targeted node was not executed before

  const pathLength = path.length
  const isNestedData = pathLength > 1

  if (isNestedData) {
    const completePath = path.slice(0, pathLength - 1).join('')
    nodeSuggestions = get(nodeSuggestions, completePath)
  }
  if (!nodeSuggestions) return []

  // There are no more keys in the JSON, so 'nodeSuggestions' contains the value of
  // the Object for the specified path.
  const isNodeSuggestionsAString = typeof nodeSuggestions === 'string'
  if (isNodeSuggestionsAString) return []

  const nodeSuggestionsKeys = Object.keys(nodeSuggestions)
  const suggestions = nodeSuggestionsKeys.map((item) => ({ function_name: item }))
  const pathRoot = path[pathLength - 1].replace('.', '')

  return suggestions.filter(suggestion => {
    const lowerCasedSuggestion = suggestion.function_name.toLowerCase()
    const lowerCasedRoot = pathRoot.toLowerCase()
    return lowerCasedSuggestion.match(lowerCasedRoot) && lowerCasedSuggestion !== lowerCasedRoot
  })
}

/**
 * @returns `null` if we're not actually iterating over the looper
 */
function getIterateOverLooperSuggestions(autocompleteSuggestions, pathItems, connector, currentFlow, diagramNodes, looperContext) {
  const rootItem = pathItems[0]
  const exactNode = diagramNodes?.find(node => node.addInfo?.selector === rootItem)
  /** todo:keyword: REFACTOR_activeAction - should get only by activeActionId */
  const currentActionName = connector?.actions?.find(action => action.id === exactNode?.addInfo.activeActionId)?.name ?? exactNode?.addInfo?.activeAction
  /** TODO:
   * Ideally we would check something like: `exactNode.addInfo.activeActionId === Helpers.Looper.Actions.ITERATE_OVER`
   *
   * There is no way to make this not hard coded with name until we can guarantee that Helper actions are created with consistent ids across environments
   */
  const isIterateOverLooper = exactNode?.addInfo?.name === 'Looper' && currentActionName === 'iterate_over'
  if (!isIterateOverLooper) return null

  const isLoopContextSelected = !R.isNil(looperContext)
  if (!isLoopContextSelected) return getLooperContextSuggestions(pathItems, exactNode)

  if (looperContext === LooperContextOption.OUTSIDE) return getFlowStepSuggestions(autocompleteSuggestions, pathItems)

  return getInsideLooperSuggestions(autocompleteSuggestions, pathItems, exactNode, currentFlow, diagramNodes)
}

/** If we are trying to get autocomplete suggestions for a looper, we first must assert whether we want the inside/outside loop suggestions.
 * Given the following structure to iterate over:
 * [
 *  {"thing": "one", "amount": 5},
 *  {"thing": "two", "amount": 3},
 *  {"thing": "three", "amount": 1}
 * ]
 *
 * Inside options: Iterating over one of the indexes we get the options: "thing", "amount".
 * Outside options: Indexes of the original structure (3 options in this case).
 *
 * We expect to only show suggestions after typing a "." ex: `iterate_looper.`
 */
function getLooperContextSuggestions(pathItems, exactNode) {
  const lastItem = pathItems[pathItems.length - 1]
  const isExpectingContextSuggestions = lastItem === '.'
  if (!isExpectingContextSuggestions) return []

  const looperId = exactNode.addInfo.id
  return [
    { function_name: LooperContextOption.INSIDE, connectorId: looperId },
    { function_name: LooperContextOption.OUTSIDE, connectorId: looperId },
  ]
}

function getInsideLooperSuggestions(autocompleteSuggestions, pathItems, exactNode, currentFlow, diagramNodes) {
  // 1. get `Loop Reference` value of looper
  const userInputOfLooper = currentFlow?.user_input[exactNode.id]
  const looperRef = userInputOfLooper?.model?.loopref?.match(jinjaPathItemRegex)
  if (!looperRef || !looperRef.length) return []

  // 2. Try to resolve `Loop Reference` to find targeted node
  const nodeSelector = looperRef.shift()
  const node = diagramNodes.find(node => node.addInfo?.selector === nodeSelector)
  if (!node) return [] // `Loop Reference` does not match any node in our diagram

  // 3. Get response_json of targeted node
  const nodeSuggestion = autocompleteSuggestions.find(({ flowRunStep }) => flowRunStep?.node_id === node.id)
  const nodeResponseJson = nodeSuggestion?.flowRunStep?.response_json
  if (!nodeResponseJson) {
    throw new ExtendedError(
      `You must run the step (${nodeSelector}) used by the looper at least once to have autocomplete suggestions`,
      ExtendedErrorSeverity.INFO,
    )
  }

  // 4. resolve remaining path of `Loop Reference` against response_json of targeted node
  const looperRefLastValue = !looperRef.length ? nodeResponseJson : looperRef.reduce(stepThroughExample, nodeResponseJson)
  if (!looperRefLastValue) return [] // Path of `Loop Reference` is invalid against last execution data of targeted node

  // 5. use the last execution data of the resolved `Loop Reference` to create suggestions
  pathItems.splice(0, 1, '.0') // use first entry of looperRefLastValue, as looperRefLastValue should always be a list
  return suggestFromExample(looperRefLastValue, pathItems, pathItems.pop(), node.addInfo?.id)
}

function getFlowStepSuggestions(autocompleteSuggestions, pathItems) {
  const rootItem = pathItems.shift()
  const flowRunStep = autocompleteSuggestions.find(R.propEq(rootItem, 'function_name'))?.flowRunStep

  const hasResponseJson = !R.isNil(flowRunStep?.response_json)
  if (hasResponseJson) {
    return suggestFromExample(flowRunStep.response_json, pathItems, pathItems.pop(), flowRunStep.connector_id)
  }

  const hasResponseSchema = !R.isNil(flowRunStep?.response_schema)
  if (hasResponseSchema) {
    return suggestFromSchema(flowRunStep.response_schema, pathItems, pathItems.pop())
  }

  return []
}

/** @param {Object.<string, GraphQLNamedType>} graphQlOptions */
export function graphQlAutocompleteOptions(graphQlOptions) {
  const autocompleteOptions = []
  for (const key in graphQlOptions) {
    if (!Object.hasOwnProperty.call(graphQlOptions, key)) continue
    const graphQlOption = graphQlOptions[key]

    /** @type {AutocompleteOption} */
    const autocompleteOption = {
      function_name: graphQlOption.name,
      icon: 'documentation', // TODO: Make suggestion render different icon according to instance `suggestion.constructor.name`
      desc: graphQlOption.description,
      fields: makeGraphQLOptionFields(graphQlOption),
    }
    /* TODO: for each value of `options.getValues()` add it as a path from the current option.
    Original option could be an enum, and the value would be one of the new options. */
    autocompleteOptions.push(autocompleteOption)
  }
  return autocompleteOptions
}

/** @param {GraphQLNamedType} option */
function makeGraphQLOptionFields(option) {
  const hasFields = typeof option.getFields === 'function'
  if (!hasFields) return undefined

  const optionFields = []
  const fields = option.getFields()
  for (const key in fields) {
    if (!Object.hasOwnProperty.call(fields, key)) continue
    const field = fields[key]
    /** @type {AutocompleteOptionField} */
    const suggestionField = { name: field.name, desc: field.description }
    optionFields.push(suggestionField)
  }
  return optionFields
}

export function sqlAutocompleteOptions({ keywords, types }) {
  /** @type {Array<AutocompleteOption>} */
  const keywordOptions = keywords.split(' ').map(keyword => ({
    function_name: keyword,
    icon: 'key',
    desc: 'SQL Keyword',
    rank: 2,
  }))

  /** @type {Array<AutocompleteOption>} */
  const typeOptions = types.split(' ').map(type => ({
    function_name: type,
    icon: 'letter-t',
    desc: 'SQL Type',
    rank: 1,
  }))

  return [...keywordOptions, ...typeOptions]
}
