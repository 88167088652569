import { Auth } from '@aws-amplify/auth'
import ElementPlus from 'element-plus'
import { createApp } from 'vue'
import SchemaFormRendererPlugin from '@/ui-libs/schema-form-renderer'
import App from '@/ui/App'
import Login from '@/ui/Login'
import Icon from '@/ui/icons'
import { i18n, currentLanguage } from '@/ui/lang'
import { configureAmplify } from '@/ui/plugins/amplify'
import icons from '@/ui/plugins/icons'
import permission from '@/ui/plugins/permission'
import sentry from '@/ui/plugins/sentry'
import router from '@/ui/router'
import store from '@/ui/store'
import { scheduleAccessTokenRenewal } from '@/ui/utils/jwt'
import 'normalize.css/normalize.css'
import 'element-plus/dist/index.css'
import 'splitpanes/dist/splitpanes.css'
import '@aws-amplify/ui-vue/styles.css'
import '@/ui-libs/syncfusion/syncfusion-material.css'
import '@/ui/styles/index.scss'
// TODO: this import is here solely to run side effects in its index.js, find a better way to do this
import '@/ui/api'

configureAmplify()

let user
let userIsAuthenticated = true
try {
  user = await Auth.currentAuthenticatedUser()
} catch (_) {
  userIsAuthenticated = false
}

const app = createApp(userIsAuthenticated ? App : Login)

if (userIsAuthenticated) {
  // Currently, we need to set the user into the store as other places expect it to be there
  // TODO: Remove setting the user into the store after updating places using it
  await store.dispatch('user/SetUser', user)

  // Access token
  await scheduleAccessTokenRenewal()

  // Global components
  app.component('Icon', Icon)

  // Plugins
  app.use(store)
  app.use(router)
  app.use(ElementPlus)
  app.use(i18n)
  app.use(SchemaFormRendererPlugin, { language: currentLanguage })
  app.use(icons)
  app.use(permission)
  app.use(sentry)
}

app.mount('#app')

window.app = app

export { app }
