<template>
  <el-upload
    :ref="elementKey"
    :model-value="model"
    action=""
    :on-change="onChangeFile"
    :auto-upload="false"
    :limit="1"
    :on-exceed="handleExceed"
  >
    <el-button type="primary">{{ $t('clickToUpload') }}</el-button>
  </el-upload>
</template>

<script>
/* eslint-disable vue/require-prop-types */

export default {
  name: 'UploadField',
  props: [
    'entry',
    'ruleModel',
    'elementKey',
    'element',
    'expandListIndex',
  ],
  emits: [
    'ruleModelChange',
  ],
  computed: {
    model: {
      get() {
        return this.ruleModel[this.elementKey]
      },
      set(newData) {
        this.$emit('ruleModelChange', {
          value: newData,
          expandListIndex: this.expandListIndex,
        })
      },
    },
  },
  methods: {
    handleExceed() {
      this.$message({
        message: this.$t('uploadLimit'),
        duration: 5000,
      })
    },
    onChangeFile(file) {
      const reader = new FileReader()
      reader.onload = (res) => { this.model = res.target.result }
      reader.onerror = (err) => console.log(err)
      reader.readAsText(file.raw)
    },
  },
}
</script>
